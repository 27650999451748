import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Message, NavLink, Divider, Container, Box, Button, Card, Text, Image, Slider, Label, Flex, Checkbox, Select, Textarea, Radio, Input, Link, Progress, Badge, Alert } from "theme-ui";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <hr></hr>
    <h1>{`Example components`}</h1>
    <Divider mdxType="Divider" />
    <h2>{`Borders`}</h2>
    <Flex sx={{
      flexWrap: "wrap"
    }} mdxType="Flex">
  <Box p={3} m={2} color="text" bg="muted" sx={{
        border: "2px solid black",
        borderRadius: "sketchy0",
        minWidth: "100px"
      }} mdxType="Box">
    sketchy0
  </Box>
  <Box p={3} m={2} color="text" bg="muted" sx={{
        border: "2px solid black",
        borderRadius: "sketchy1",
        minWidth: "100px"
      }} mdxType="Box">
    sketchy1
  </Box>
  <Box p={3} m={2} color="text" bg="muted" sx={{
        border: "2px solid black",
        borderRadius: "sketchy2",
        minWidth: "100px"
      }} mdxType="Box">
    sketchy2
  </Box>
  <Box p={3} m={2} color="text" bg="muted" sx={{
        border: "2px solid black",
        borderRadius: "sketchy3",
        minWidth: "100px"
      }} mdxType="Box">
    sketchy3
  </Box>
  <Box p={3} m={2} color="text" bg="muted" sx={{
        border: "2px solid black",
        borderRadius: "sketchy4",
        minWidth: "100px"
      }} mdxType="Box">
    sketchy4
  </Box>
    </Flex>
    <hr></hr>
    <h2>{`Buttons`}</h2>
    <Button variant="primary" m={10} mdxType="Button">
  Default
    </Button>
    <Button variant="danger" m={10} mdxType="Button">
  Danger
    </Button>
    <Button variant="info" m={10} mdxType="Button">
  Info
    </Button>
    <Button variant="warning" m={10} mdxType="Button">
  Warning
    </Button>
    <Button variant="success" m={10} mdxType="Button">
  Success
    </Button>
    <hr></hr>
    <h2>{`Form elements`}</h2>
    <Box as='form' pb={3} onSubmit={e => e.preventDefault()} sx={{
      width: '100%',
      maxWidth: "500px"
    }} mdxType="Box">
  <Label htmlFor='username' mdxType="Label">Username</Label>
  <Input name='username' mb={3} mdxType="Input" />
  <Label htmlFor='password' mdxType="Label">Password</Label>
  <Input type='password' name='password' mb={3} mdxType="Input" />
  <Box mdxType="Box">
    <Label mb={3} mdxType="Label">
      <Checkbox mdxType="Checkbox" />
      Remember me
    </Label>
  </Box>
  <Label htmlFor='sound' mdxType="Label">Sound</Label>
  <Select name='sound' mb={3} mdxType="Select">
    <option>Beep</option>
    <option>Boop</option>
    <option>Blip</option>
  </Select>
  <Label htmlFor='comment' mdxType="Label">Comment</Label>
  <Textarea name='comment' rows='6' mb={3} mdxType="Textarea" />
  <Flex mb={3} mdxType="Flex">
    <Label mdxType="Label">
      <Radio name='letter' mdxType="Radio" /> Alpha
    </Label>
    <Label mdxType="Label">
      <Radio name='letter' mdxType="Radio" /> Bravo
    </Label>
    <Label mdxType="Label">
      <Radio name='letter' mdxType="Radio" /> Charlie
    </Label>
  </Flex>
  <Button mdxType="Button">
    Submit
  </Button>
    </Box>
    <hr></hr>
    <h2>{`Links`}</h2>
    <Box pb={20} mdxType="Box">
  <Link href='#!' mdxType="Link">Hello</Link>
    </Box>
    <hr></hr>
    <h2>{`Badges`}</h2>
    <Box pb={20} mdxType="Box">
  <Badge variant='accent' mdxType="Badge">New</Badge>
  <Badge variant='outline' ml={2} mdxType="Badge">Cool</Badge>
    </Box>
    <hr></hr>
    <h2>{`Alerts`}</h2>
    <Box pb={20} mdxType="Box">
  <Alert variant="danger" m={10} mdxType="Alert">
    Danger
  </Alert>
  <Alert variant="info" m={10} mdxType="Alert">
    Info
  </Alert>
  <Alert variant="warning" m={10} mdxType="Alert">
    Warning
  </Alert>
  <Alert variant="success" m={10} mdxType="Alert">
    Success
  </Alert>
    </Box>
    <hr></hr>
    <h2>{`Navigation`}</h2>
    <Box pb={20} mdxType="Box">
  <Flex as='nav' mdxType="Flex">
    <NavLink href='#!' p={2} mdxType="NavLink">
      Home
    </NavLink>
    <NavLink href='#!' p={2} mdxType="NavLink">
      Blog
    </NavLink>
    <NavLink href='#!' p={2} mdxType="NavLink">
      About
    </NavLink>
  </Flex>
    </Box>
    <hr></hr>
    <h2>{`Table`}</h2>
    <table style={{
      paddingBottom: 20
    }}>
    <thead>
        <tr>
            <th colSpan="2">The table header</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>The table body</td>
            <td>with two columns</td>
        </tr>
    </tbody>
    </table>
    <hr></hr>
    <h2>{`Card`}</h2>
    <Card mb={5} sx={{
      maxWidth: 400,
      padding: "30px"
    }} mdxType="Card">
  <Text mdxType="Text">
    Cupcake ipsum dolor sit amet chocolate bar. Apple pie macaroon muffin jelly candy cake soufflé muffin croissant. Gummies jelly beans cotton candy fruitcake. Wafer lemon drops soufflé cookie. Sesame snaps fruitcake cheesecake danish toffee marzipan biscuit.
  </Text>
    </Card>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      